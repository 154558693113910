import styled, { css } from 'styled-components';

import { TextAlign, TextColor, TextSize, TextTransform, TextWeight } from '@Helpers/types/text';

export interface TextProps {
  $weight: TextWeight;
  $size: TextSize;
  $mobileSize?: TextSize;
  $color: TextColor;
  $textAlign: TextAlign;
  onClick?: () => void;
  $textTransform: TextTransform;
  $isTruncated: boolean;
  $inline: boolean;
}

export const defaultTextStyles = (textProps: TextProps) => css`
  word-wrap: break-word;
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text[textProps.$weight][textProps.$size].fontSize};
  font-weight: ${(props) => props.theme.font.text[textProps.$weight][textProps.$size].fontWeight};
  line-height: ${(props) => props.theme.font.text[textProps.$weight][textProps.$size].lineHeight};
  letter-spacing: ${(props) => props.theme.font.text[textProps.$weight][textProps.$size].letterSpacing};
  color: ${(props) => props.theme.palette.text[textProps.$color]};

  transition: color ${(props) => props.theme.transition.theme};

  text-align: ${() => textProps.$textAlign};
  text-transform: ${() => textProps.$textTransform};

  @media (max-width: ${(props) => props.theme.device.tablet}) {
    font-size: ${(props) =>
      props.theme.font.text[textProps.$weight][textProps.$mobileSize ?? textProps.$size].fontSize};
  }
  ${() =>
    textProps.$inline &&
    css`
      display: inline;
    `}
  ${() =>
    textProps.onClick &&
    css`
      cursor: pointer;
    `}
  ${() =>
    textProps.$isTruncated &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  b,
  strong {
    font-weight: ${(props) => props.theme.font.text.semibold[textProps.$size].fontWeight};
  }
`;

export const Root = styled.p<TextProps>`
  ${(props) => defaultTextStyles(props)}
`;
