import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface UnsavedChangesState {
  isNavigationBlocked: boolean;
  pendingRoute: string | null;
}

interface UnsavedChangesAction {
  setIsNavigationBlocked: (isNavigationBlocked: boolean) => void;
  setPendingRoute: (pendingRoute: string | null) => void;
}

// todo: could be removed if next offers a better solution, see: https://github.com/vercel/next.js/discussions/41934#discussioncomment-12077414
const useUnsavedChangesStore = create<UnsavedChangesState & UnsavedChangesAction>()(
  devtools((set): UnsavedChangesState & UnsavedChangesAction => ({
    isNavigationBlocked: false,
    pendingRoute: null,
    setIsNavigationBlocked: (isNavigationBlocked) => {
      set({
        isNavigationBlocked,
      });
    },
    setPendingRoute: (pendingRoute) => {
      set({
        pendingRoute,
      });
    },
  }))
);

export default useUnsavedChangesStore;
